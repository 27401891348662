import React from "react"
import querystring from "querystring"

import { Location } from "@reach/router"

const stripLeadingQuestionMark = (stringToStrip: string) => {
  if (stringToStrip[0] === "?") {
    return stringToStrip.slice(1)
  }
  return stringToStrip
}

const safelyGetSearch = (searchString: string) => {
  const hasSearch = !!searchString
  if (!hasSearch) {
    return {}
  }

  const cleanedString = stripLeadingQuestionMark(searchString)
  return querystring.parse(cleanedString)
}

const WithLocation = ChildComponent => props => (
  <Location>
    {({ location, navigate }) => (
      <ChildComponent
        {...props}
        location={location}
        navigate={navigate}
        search={safelyGetSearch(location.search)}
      />
    )}
  </Location>
)

export default WithLocation
