import React from "react"

interface ShouldShowProps {
  show: boolean
}
const ShouldShow: React.FC<ShouldShowProps> = ({ show, children }) => (
  <React.Fragment>{show ? children : null}</React.Fragment>
)

export default ShouldShow
