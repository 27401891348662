import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import styled, { keyframes } from "styled-components"
import typography from "../utils/typography"
import axios from "axios"

import WithLocation from "../components/withLocation"
import Layout, { StyledLink, DetailSection } from "../components/layout"
import SEO from "../components/seo"

import { HeroWrapper, HeroHeading } from "../components/hero/heroTitle"
import Reveal from "../components/reveal"
import RsvpForm from "../components/forms/rsvpForm"

const Hero = styled.div`
  display: grid;
  min-height: 20vh;
  grid-column: main / gutter-right;
  align-content: flex-end;

  @media screen and (min-width: 65rem) {
    min-height: 19.5rem;
  }
`

const Content = styled.div`
  max-width: 17.75rem;
  margin-top: 2.5rem;
  margin-left: auto;
  font-size: 0.875rem;

  @media screen and (min-width: 65rem) {
    max-width: 24.5rem;
    margin-top: 6.5rem;
    font-size: 1rem;
  }
`

const Offset = styled.div`
  display: grid;
  grid-column: 2 / -1; /* start position from DetailSection */

  @media screen and (min-width: 46rem) {
    grid-column: 3 / -1; /* start position from DetailSection */
  }

  @media screen and (min-width: 56rem) {
    grid-column: 4 / -1; /* start position from DetailSection */
  }
`

const HeadingLabel = styled.span`
  /* margin-bottom: 1.5rem; */
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.25px;
`

const RsvpPage = ({ search, navigate, props }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const email = search.email

    // In case someone tries to navigate directly here
    if (!email) {
      navigate("/404")
    } else {
      // FIXME: Remove this setTimeout for prod
      setTimeout(() => {
        axios
          .get(`/.netlify/functions/checkGuest?guestEmail=${email}`)
          .then(({ data }) => {
            console.log(data)
            setUser(data)
          })
          .catch(err => {
            console.log(err)
            navigate("/guest-not-found", {
              state: { email },
            })
          })
      }, 3000)
    }
  }, [])

  return (
    <Layout>
      <SEO title="RSVP" />
      <Hero>
        {user && (
          <HeroWrapper>
            <Reveal delay="0">
              <HeadingLabel>Welcome,</HeadingLabel>
            </Reveal>
            <HeroHeading>{user.name}</HeroHeading>
          </HeroWrapper>
        )}
      </Hero>

      <DetailSection>
        <Offset>
          {/* <Reveal delay="2.15s"> */}
          {user && (
            <Content>
              <RsvpForm user={user}></RsvpForm>
            </Content>
          )}
        </Offset>
      </DetailSection>
    </Layout>
  )
}

export default WithLocation(RsvpPage)
