import React, { useState, SyntheticEvent } from "react"
import { navigate, Link, navigateTo } from "gatsby"
import styled, { ThemeProps, ThemedStyledProps } from "styled-components"
import axios from "axios"
import { Field, Form, FormikProps, Formik } from "formik"

import WaveButton from "../buttons/waveButton"
import TextInput from "../textInput"
import RadioItem from "../radioItem"
import { textIn } from "../reveal"
import ShouldShow from "../utils/shouldShow"

const FormGroup = styled.div`
  margin-bottom: 5rem;
`

const HelperText = styled.p`
  margin-bottom: 2rem;
  font-size: 0.875rem;
`

const Label = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
`

const StyledForm = styled.div`
  margin-bottom: 2.5rem;

  input[type="text"],
  input[type="email"] {
    width: 100%;
  }

  label {
    font-size: 1.25rem;

    p {
      margin-bottom: 0.75rem;
    }
  }
`

interface FormEvent extends SyntheticEvent {
  target: HTMLFormElement
}

interface User {
  hasReceivedRsvp: boolean
  isConfirmed: boolean
  isAttending: boolean
  dietaryRestrictions: string[]
  _id: string
  name: string
  isPlusOneEligible: boolean
  plusOneId: User | null
  email: string
}

interface Values {
  attending: string
  withGuest: string
  guestName: string
  guestEmail: string
}

interface RsvpFormProps {
  user: User
}

// TODO 2/26/2020: Debate having it pull from location state for security's sake, or use the uuid endpoint from wedding-api
const RsvpForm: React.FC<RsvpFormProps> = props => {
  const initialValues: Values = {
    attending: props.user.isConfirmed ? "yes" : "no",
    withGuest: props.user.isPlusOneEligible ? "yes" : "no",
    guestName: props.user?.plusOneId?.name || "",
    guestEmail: props.user?.plusOneId?.email || "",
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        console.log(values)
      }}
    >
      {(formikProps: FormikProps<Values>) => {
        return (
          <Form>
            <FormGroup>
              <Label>Are you joining us on Sunday, September 27, 2020?</Label>
              <Field name="attending">
                {({ field: { name, onBlur, onChange } }) => (
                  <>
                    <RadioItem
                      id="attending--yes"
                      name={name}
                      value="yes"
                      checked={formikProps.values.attending === "yes"}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      Yes
                    </RadioItem>
                    <RadioItem
                      required
                      id="attending--no"
                      name={name}
                      value="no"
                      checked={formikProps.values.attending === "no"}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      No
                    </RadioItem>
                  </>
                )}
              </Field>
            </FormGroup>
            <ShouldShow
              show={
                formikProps.values.attending === "yes" &&
                props.user.isPlusOneEligible
              }
            >
              <FormGroup>
                <Label>Are you bringing a guest?</Label>
                <Field name="withGuest">
                  {({ field: { name, onBlur, value } }) => (
                    <>
                      <RadioItem
                        id="with-guest--yes"
                        name={name}
                        value="yes"
                        checked={formikProps.values.withGuest === "yes"}
                        onChange={formikProps.handleChange}
                        onBlur={onBlur}
                      >
                        Yes
                      </RadioItem>
                      <RadioItem
                        id="with-guest--no"
                        name={name}
                        value="no"
                        checked={formikProps.values.withGuest === "no"}
                        onChange={formikProps.handleChange}
                        onBlur={onBlur}
                      >
                        No
                      </RadioItem>
                    </>
                  )}
                </Field>
              </FormGroup>
            </ShouldShow>
            <ShouldShow
              show={
                formikProps.values.attending === "yes" &&
                formikProps.values.withGuest === "yes"
              }
            >
              <StyledForm>
                <HelperText>
                  If you don’t know these details yes, you can fill this out
                  later. Just make sure it’s finalized by August 1.
                </HelperText>
                <Field name="guestName">
                  {({ field: { name } }) => (
                    <TextInput
                      type="text"
                      label="Guest name"
                      name={name}
                      value={formikProps.values.guestName}
                      onChange={formikProps.handleChange}
                    />
                  )}
                </Field>
                <Field name="guestEmail">
                  {({ field: { name } }) => (
                    <TextInput
                      type="text"
                      label="Guest email"
                      name={name}
                      value={formikProps.values.guestEmail}
                      onChange={formikProps.handleChange}
                    >
                      <HelperText>
                        optional, we’ll send them a confirmation email with
                        details.
                      </HelperText>
                    </TextInput>
                  )}
                </Field>
              </StyledForm>
            </ShouldShow>
            <WaveButton handleClick={() => {}}>Confirm invite</WaveButton>
            {/* <GhostButton handleClick={() => handleCancel()}>cancel</GhostButton> */}
          </Form>
        )
      }}
    </Formik>
  )
}

export default RsvpForm
