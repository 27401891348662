import React from "react"
import styled, { ThemeProps, ThemedStyledProps } from "styled-components"
import typography from "../utils/typography"
import { textIn } from "./reveal"

const inter = typography.options.bodyFontFamily.join()
const ogg = typography.options.headerFontFamily.join()

const Radio = styled.div`
  display: flex;
`

const Input = styled.input.attrs(props => ({
  type: "radio",
}))``

const Label = styled.label`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  font-family: ${ogg};
  font-size: 2.25rem;
  line-height: 1.25;
  text-transform: lowercase;
  opacity: 0.5;
  transition: opacity 300ms ease-in;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    box-sizing: border-box;
    margin-top: 0.375rem; /* visually center */
    margin-right: 1rem;
    border-radius: 100%;
    background-color: transparent;
    box-shadow: 0 0 0 1px,
      inset 0 0 0 0.25rem ${props => props.theme.colors.background};
    cursor: pointer;
    text-align: center;
    transition: background-color 200ms ease-out;
  }

  input[type="radio"]:checked + & {
    opacity: 1;
  }

  input[type="radio"]:checked + &::before {
    background-color: ${props => props.theme.colors.primaryColor};
  }
`

interface RadioItemProps {
  name: string
  id: string
  value: string
  onChange: (e: React.ChangeEvent) => void
  onBlur: () => void
  checked: boolean
  required?: boolean
}

const RadioItem: React.FC<RadioItemProps> = props => (
  <Radio>
    <Input
      type="radio"
      className="sr-only"
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      checked={props.checked}
      required={props.required}
    />
    <Label htmlFor={props.id}>{props.children}</Label>
  </Radio>
)

export default RadioItem
