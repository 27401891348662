import React from "react"
import styled, { ThemeProps, ThemedStyledProps } from "styled-components"
import typography from "../../utils/typography"
import { textIn } from "../reveal"
import cursor from "../../images/grimace.png"

const inter = typography.options.bodyFontFamily.join();

interface ButtonProps {
  marginTop: string;
  marginBottom: string;
}

const Button = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: .5;
    cursor: url(${cursor}), auto;
  }
`

const Svg = styled.svg`
  display: block;
  max-width: 100%;
`

const Text = styled.text`
  font-family: ${inter};
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  fill: ${props => props.theme.colors.primaryColor};
`

const WaveButton = ({ handleClick, children, disabled }) => (
  <Button onClick={handleClick} disabled={disabled}>
    <Svg width="200" height="40" viewBox="-4 0 240 4" >
      <path id="wave" fill="none" d="M0,11 C17.8333333,3.66666667 35.6666667,0 53.5,0 C71.3333333,0 89.1666667,3.66666667 107,11 C124.833333,18.3333333 142.666667,22 160.5,22 C178.333333,22 196.166667,18.3333333 214,11" />
      <Text>
        <textPath xlinkHref="#wave">{children}</textPath>
      </Text>
    </Svg>
  </Button>
)

export default WaveButton
